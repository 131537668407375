import { Typography, Box, useMediaQuery, useTheme, styled, keyframes } from "@mui/material";
import Coin from "../images/Klink-Coin.svg";
import React, { useEffect, useState } from "react";
import Arrow from "../images/Arrowright.svg";
import KlinkCoin from "../images/KlinkCoin.svg"
import multitapIMG from '../images/Multitap.svg'
import RechargingSpeedIMG from '../images/RechargingSpeed.svg'
import EnergyLimitIMG from '../images/EnergyLimit.svg'
import TonPerTapIMG from '../images/TonPerTap.svg'
import Ton1x from '../images/1X.svg'
import Ton5x from '../images/5x.svg'
import Ton10x from '../images/10x.svg'
import Ton50x from '../images/50x.svg'
import Ton100x from '../images/100x.svg'
import Ton500x from '../images/500x.svg'
import Ton1 from '../images/Ton1.svg'
import Ton2 from '../images/Ton2.svg'
import Ton3 from '../images/Ton3.svg'
import Ton4 from '../images/Ton4.svg'
import CountUp from 'react-countup';
import { useDispatch, useSelector } from "react-redux";
import MultitapModal from "../components/Modals/MultitapModal";
import ThreeSectionLodder from "../components/ThreeSectionLodder";
import UserRegisterStatus from "../components/UserRegisterStatus";
import { BuyUserTonTickets, getUserTonTickets } from "../Apis";
import SuccessModal from "../components/Modals/SuccessModal";
import KlinkToken from '../images/Klink-Coin.svg'
import { FunctionCommas } from "../utils/commasFun";
import Popup from "../components/Popup";
import { useNavigate } from "react-router-dom";
import SuccessFailPopUp from "../components/SuccessFailPopUp";
import notEnoughPointCoin from "../images/notEnoughPointCoin.svg"
const Boost = ({ multitap, RechargingSpeed, EnergyLimit }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:370px)");
  const [fade, setFade] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const accessToken = useSelector((state) => state?.user?.accessToken)

  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.user);
  // useEffect(() => {
  //   // dispatch(getUserReferal(userId));
  //   dispatch(getUserTonTickets(userId,setLoading,accessToken));

  // }, [dispatch, userId]);


  // multitap seletector
  const userData = useSelector((state) => state?.user?.userData?.boosts?.multiTap)
  const nextCost = useSelector((state) => state?.user?.userData?.boosts?.multiTap?.nextCost)
  const currentLevel = useSelector((state) => state?.user?.userData?.boosts?.multiTap?.currentLevel)
  const nextLevel = useSelector((state) => state?.user?.userData?.boosts?.multiTap?.nextLevel)
  // const  currentValue = useSelector((state) => state?.user?.userData?.boosts?.multiTap?.currentValue)
  const nextValue = useSelector((state) => state?.user?.userData?.boosts?.multiTap?.nextValue)
  const currentPoint = useSelector((state) => state?.point?.currentPoint)

  //energy limit 
  const nextCostenergylimit = useSelector((state) => state?.user?.userData?.boosts?.energyLimit?.nextCost)
  const currentLevelenergylimit = useSelector((state) => state?.user?.userData?.boosts?.energyLimit?.currentLevel)
  const nextLevelenergylimit = useSelector((state) => state?.user?.userData?.boosts?.energyLimit?.nextLevel)
  // const  currentValue = useSelector((state) => state?.user?.userData?.boosts?.multiTap?.currentValue)
  const nextValueenergylimit = useSelector((state) => state?.user?.userData?.boosts?.energyLimit?.nextValue)


  //reacharging-speed
  // const userDatarechargingspeed = useSelector((state) => state?.user?.userData?.boosts?.rechargingSpeed)
  const nextCostRechargingspeed = useSelector((state) => state?.user?.userData?.boosts?.rechargingSpeed?.nextCost)
  const currentLevelRechargingspeed = useSelector((state) => state?.user?.userData?.boosts?.rechargingSpeed?.currentLevel)
  const nextLevelRechargingspeed = useSelector((state) => state?.user?.userData?.boosts?.rechargingSpeed?.nextLevel)
  const nextValueRechargingspeed = useSelector((state) => state?.user?.userData?.boosts?.rechargingSpeed?.nextValue)
  // const  currentValueRechargingspeed = useSelector((state) => state?.user?.userData?.boosts?.rechargingSpeed?.currentValue)


  const userTonTickets = useSelector((state) => state?.user?.userTonTickets)
  const numberOfTickets = useSelector((state) => state?.user?.userTonTickets?.numberOfTickets)
  // console.log("numberOfTickets", numberOfTickets);





  const navigate = useNavigate();


  // Popup state
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [success, setSuccess] = useState(false);
  const [resultPopUp, setResultPopUp] = useState(false);
  const [modalLoadder, setModalLoadder] = useState(false);
  const [initialUserPoint, setInitialUserPoint] = useState(currentPoint);
  const [notEnoughPoint, setNotEnoughPoint] = useState(false);
  const [popupMessage, setPopUpMessage] = useState('');
  const [trafficLight, setTafficLight] = useState(false);



  const tokens = [
    {
      TokenName: '1,000,000 Points',
      TokenDescription: '1 x TON Paid to Klink Wallet',
      image: Ton1,
    },
    {
      TokenName: '5,000,000 Points',
      TokenDescription: '5 x TON Paid to Klink Wallet',
      image: Ton2,
    },
    {
      TokenName: '20,000,000 Points',
      TokenDescription: '20 x TON Paid to Klink Wallet',
      image: Ton3
    },
    {
      TokenName: '150,000,000 Points',
      TokenDescription: '150 x TON Paid to Klink Wallet',
      image: Ton4
    }

  ];





  const handleOpenModal = (type) => {
    let content = {};
    switch (type) {
      case 'multitap':
        content = {
          type: 'multitap',
          icon: multitapIMG,
          title: 'Multitap',
          description1: 'Increase the amount of points you earn per tap!',
          description2: `+${nextValue} per tap for each level.`,
          coinIcon: Coin,
          nextCost: nextCost,
          nextLevel: nextLevel,
        };
        break;
      case 'energyLimit':
        content = {
          type: 'energyLimit',
          icon: EnergyLimitIMG,
          title: 'Energy Limit',
          description1: 'Increase the speed of energy bar recharge  ',
          description2: `+ ${nextValueenergylimit}  energy limit for each level `,
          coinIcon: Coin,
          nextCost: nextCostenergylimit,
          nextLevel: nextLevelenergylimit,
        };
        break;
      case 'rechargingSpeed':
        content = {
          type: 'rechargingSpeed',
          icon: RechargingSpeedIMG,
          title: 'Recharging Speed',
          description1: 'Increase the speed of energy bar recharge ',
          description2: `+ ${nextValueRechargingspeed} per second `,
          coinIcon: Coin,
          nextCost: nextCostRechargingspeed,
          nextLevel: nextLevelRechargingspeed,
        };
        break;
      default:
        break;
    }
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const handleBoxClick = (id, ticketCost) => {
    if (ticketCost > currentPoint) {

      setNotEnoughPoint(true);
      return;
    }
    dispatch(BuyUserTonTickets(userId, id, accessToken, navigate, setResultPopUp, setSuccess, setModalLoadder, setOpenModal, setPopUpMessage))
  };

  const handleClose = () => {
    setModalOpen(false);
  };


  useEffect(() => {
    setTimeout(() => {
      setTafficLight(false);
    }, 5000)
  }, [trafficLight])

  useEffect(() => {
    setFade(true);
  }, []);


  const getImageByCost = (cost) => {
    switch (cost) {
      case 1:
        return Ton1x;
      case 5:
        return Ton5x;
      case 10:
        return Ton10x;
      case 50:
        return Ton50x;
      case 100:
        return Ton100x;
      case 500:
        return Ton500x;
      default:
        return Ton1x; // Fallback to a default image
    }
  };


  const boxFromLeft = keyframes`
  0% {
        transform: translateX(10%);
     
    }
    100% {
        transform: translateX(0);
      
    }
`;

  const boxFromRight = keyframes`
  0% {
        transform: translateX(-10%);
     
    }
    100% {
        transform: translateX(0);
      
    }
`;
  const fromTop = keyframes`
  0% {
        transform: translateY(-15%);
        opacity: 0;
    }
   
    50%{
 opacity: 0.5;


    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;
  const slideUp = keyframes`
0% {
  transform: translateY(100%);
  opacity: 0;
}
100% {
  transform: translateY(0);
  opacity: 1;
}
`;
  return (
    <>

      {!userData ? (
        <Box sx={{ display: 'flex', width: '100vw',  justifyContent: 'center', alignItems: 'center' }}>
          <ThreeSectionLodder />
        </Box>
      ) : (
        <> <UserRegisterStatus />
          <Box
            style={{
              height: "80vh",
              overflow: "hidden",
              overflowY: "auto",
              background: "transparent",
              marginTop: "29px",
              "@media (max-height:600px)": {
                marginTop: "10vh",
              },
              "@media (max-height:800px)": {
                marginTop: "8vh",
              },
              "@media (max-height:1000px)": {
                marginTop: "6vh",
              },
            }}
            className={fade ? 'fade-in' : ''}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",

                gap: "40px",
                // Width: "100%",
                // width: isSmallScreen ? "100%" : "",
                marginX: "auto",
                paddingX: !isSmallScreen ? "23px" : "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  // border: "1px solid #3772FF",

                  width: "100%",
                  flexDirection: "column",
                  borderRadius: "23px",
                  paddingY: "15px",
                  // backgroundImage: "linear-gradient(#3772FF)",
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16.94px",
                    fontWeight: 400,
                    color: "#F4FDFF",
                    fontFamily: "Inter",
                  }}
                >
                  Point  Balance
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "7px",
                    marginTop: "12px",
                    animation: `${fromTop} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
                  }}
                >
                  <img src={KlinkToken} alt="coin.svg" width={38} height={38} />
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      fontWeight: 700,
                      fontSize: "42px",
                      color: "#FFFFFF",
                      fontFamily: "Inter",
                      lineHeight: "50.4px"

                    }}
                  >
                    {/* {FunctionCommas(currentPoint)} */}
                    <CountUp duration={5} preserveValue={true} start={initialUserPoint} end={currentPoint} />
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    marginBottom: "20px",
                    marginLeft: "-10px",
                    animation: `${fromTop} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
                  }}
                >
                  {trafficLight && <Popup message={"Your have reached maximum level"} status={"PENDING"} />}
                </Box>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontSize: "18px",
                    fontFamily: "Inter",
                    lineHeight: "23.4px"
                  }}
                >
                  Boosters
                </Typography>

                <div>
                  {openModal && <MultitapModal setTafficLight={setTafficLight} setPopUpMessage={setPopUpMessage} setOpenModal={setOpenModal} setNotEnoughPoint={setNotEnoughPoint} setModalLoadder={setModalLoadder} loadder={modalLoadder} setSuccess={setSuccess} setResultPopUp={setResultPopUp} onClose={handleCloseModal} content={modalContent} />}
                  {resultPopUp && <SuccessFailPopUp navigate={navigate} customMessage={popupMessage} setResultPopUp={setResultPopUp} success={success} />}


                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: 'black',
                      border: '1px solid #313130',
                      paddingX: '14px',
                      paddingY: '5px',
                      borderRadius: '10px',
                      height: '80px',
                      marginTop: '15px',
                      bgcolor: '#15131D',
                      animation: `${boxFromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
                    }}
                    onClick={() => handleOpenModal('multitap')}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '13px',
                        bgcolor: '#15131D',
                      }}
                    >
                      <Box>
                        <img src={multitapIMG} width={60} height={60} style={{ objectFit: "cover" }} alt="multiTapIMG" />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          component="p"
                          variant="body1"
                          sx={{
                            color: '#FFFFFF',
                            fontFamily: 'Inter',
                            fontWeight: 800,
                          }}
                        >
                          Multitap
                        </Typography>
                        <Typography
                          component="p"
                          variant="body1"
                          sx={{
                            color: '#FFFFFF',
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: {
                              xs: '10px', // Font size 10px for extra small screens (below 400px)
                              sm: '12px', // Font size 12px for small screens and above
                            },
                          }}
                        >
                          Increase the amount you earn per tap
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '5px',
                            marginTop: "10px"
                          }}
                        >
                          <img src={Coin} alt="Coin" width={18} height={18} />
                          <Typography
                            sx={{
                              color: '#F4FDFF',
                              fontWeight: 700,
                              fontSize: '12px',
                              fontFamily: 'Inter',
                            }}
                          >
                            {nextCost} <span style={{ color: '#626160' }}> | Level {currentLevel}</span>
                          </Typography>
                        </Box>

                      </Box>
                    </Box>
                    <img src={Arrow} alt="Arrow" width={32} height={32} style={{ marginTop: '25px' }} />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: 'black',
                      border: '1px solid #313130',
                      paddingX: '14px',
                      paddingY: '5px',
                      borderRadius: '10px',
                      height: '80px',
                      marginTop: '10px',
                      bgcolor: '#15131D',
                      animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
                    }}
                    onClick={() => handleOpenModal('rechargingSpeed')}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '13px',
                      }}
                    >
                      <Box>
                        <img src={RechargingSpeedIMG} alt="RechargingSpeedIMG" width={60} height={60} />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          component="p"
                          variant="body1"
                          sx={{
                            color: '#FFFFFF',
                            fontFamily: 'Inter',
                            fontWeight: 800,
                          }}
                        >
                          Recharging Speed
                        </Typography>
                        <Typography sx={{
                          color: '#FFFFFF',
                          fontFamily: 'Inter',
                          fontWeight: 400,
                          fontSize: {
                            xs: '10px', // Font size 10px for extra small screens (below 400px)
                            sm: '12px', // Font size 12px for small screens and above
                          },
                        }} >
                          Increase the speed of recharge
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '5px',
                            marginTop: "10px"
                          }}
                        >
                          <img src={Coin} alt="Coin" width={18} height={18} />
                          <Typography
                            sx={{
                              color: '#F4FDFF',
                              fontWeight: 700,
                              fontSize: '12px',
                              fontFamily: 'Inter',
                            }}
                          >
                            {nextCostRechargingspeed} <span style={{ color: '#626160' }}> | Level {currentLevelRechargingspeed}</span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <img src={Arrow} alt="Arrow" width={32} height={32} style={{ marginTop: '25px' }} />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: 'black',
                      border: '1px solid #313130',
                      paddingX: '14px',
                      paddingY: '5px',
                      borderRadius: '10px',
                      height: '80px',
                      marginTop: '10px',
                      bgcolor: '#15131D',
                      animation: `${boxFromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
                    }}
                    onClick={() => handleOpenModal('energyLimit')}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '13px',
                      }}
                    >
                      <Box>
                        <img src={EnergyLimitIMG} alt="EnergyLimitIMG" width={60} height={60} />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          component="p"
                          variant="body1"
                          sx={{
                            color: '#FFFFFF',
                            fontFamily: 'Inter',
                            fontWeight: 800,
                          }}
                        >
                          Energy Limit
                        </Typography>
                        <Typography sx={{
                          color: '#FFFFFF',
                          fontFamily: 'Inter',
                          fontWeight: 400,
                          fontSize: {
                            xs: '10px', // Font size 10px for extra small screens (below 400px)
                            sm: '12px', // Font size 12px for small screens and above
                          },
                        }} >
                          Increase the limit in energy bar
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '5px',
                            marginTop: "10px"
                          }}
                        >
                          <img src={Coin} alt="Coin" width={18} height={18} />
                          <Typography
                            sx={{
                              color: '#F4FDFF',
                              fontWeight: 700,
                              fontSize: '12px',
                              fontFamily: 'Inter',
                            }}
                          >
                            {nextCostenergylimit}  <span style={{ color: '#626160' }}> | Level {currentLevelenergylimit}</span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <img src={Arrow} alt="Arrow" width={32} height={32} style={{ marginTop: '25px' }} />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: 'black',
                      border: '1px solid #313130',
                      paddingX: '14px',
                      paddingY: '5px',
                      borderRadius: '10px',
                      height: '80px',
                      marginTop: '10px',
                      bgcolor: '#15131D',
                      animation: `${boxFromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
                    }}
                  // onClick={() => handleOpenModal('energyLimit')}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '13px',
                      }}
                    >
                      <Box>
                        <img src={TonPerTapIMG} alt="EnergyLimitIMG" width={60} height={60} />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          component="p"
                          variant="body1"
                          sx={{
                            color: '#FFFFFF',
                            fontFamily: 'Inter',
                            fontWeight: 800,
                          }}
                        >
                          Ton Ticket Minute
                        </Typography>
                        <Typography sx={{
                          color: '#FFFFFF',
                          fontFamily: 'Inter',
                          fontWeight: 400,
                          fontSize: {
                            xs: '10px', // Font size 10px for extra small screens (below 400px)
                            sm: '12px', // Font size 12px for small screens and above
                          },
                        }} >
                          Each tap earns a Ton pool ticket
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '5px',
                            marginTop: "10px"
                          }}
                        >
                          <img src={Coin} alt="Coin" width={18} height={18} />
                          <Typography
                            sx={{
                              color: '#F4FDFF',
                              fontWeight: 700,
                              fontSize: '12px',
                              fontFamily: 'Inter',
                            }}
                          >
                            {/* {nextCostenergylimit} */}
                            200
                            <span style={{ color: '#626160' }}> | Level
                              {/* {currentLevelenergylimit} */} 1
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <img src={Arrow} alt="Arrow" width={32} height={32} style={{ marginTop: '25px' }} />
                  </Box>
                </div>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontSize: "18px",
                    fontFamily: "Inter",
                    lineHeight: "23.4px"
                  }}
                >
                  TON Prize Pool Tickets
                </Typography>
                {/* <Typography
                  sx={{
                    color: "#F4FDFF",
                    fontWeight: 400,
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  Use points to claim direct rewards into your Klink account
                </Typography> */}
                {userTonTickets.map((ticket) => (
                  <Box
                    key={ticket._id}

                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "black",
                      border: "1px solid #313130",
                      paddingX: "14px",
                      paddingY: "5px",
                      borderRadius: "10px",
                      marginTop: "15px",
                      bgcolor: "#15131D",
                    }}
                    onClick={() => handleBoxClick(ticket._id, ticket?.ticketCost)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "13px",
                        height: "80px",
                      }}
                    >
                      <Box>
                        <img src={getImageByCost(ticket.numberOfTickets)} width={60} height={60} alt="Ticket" />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          component="p"
                          variant="body1"
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Inter",
                            fontWeight: 700,
                          }}
                        >
                          {FunctionCommas(ticket?.ticketCost)} Points
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "2px",
                          }}
                        >
                          {/* <img src={Coin} alt="Coin" width={18} height={18} /> */}
                          <Typography
                            sx={{
                              color: "#FFFFFF",
                              fontWeight: 400,
                              fontSize: {
                                xs: '12px', // Font size 10px for extra small screens (below 400px)
                                sm: '15px', // Font size 12px for small screens and above
                              },
                              fontFamily: "Inter",
                            }}
                          >
                            {ticket.ticketDescription}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <img src={Arrow} alt="Arrow" />
                  </Box>
                ))}


              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontSize: "18px",
                    fontFamily: "Inter",
                    lineHeight: "23.4px"
                  }}
                >
                  TON Tokens
                </Typography>
                {/* <Typography
                  sx={{
                    color: "#F4FDFF",
                    fontWeight: 400,
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  Use points to claim direct rewards into your Klink account
                </Typography> */}
                {tokens.map((token, index) => (
                  <Box
                    key={token._id}

                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "black",
                      border: "1px solid #313130",
                      paddingX: "14px",
                      paddingY: "5px",
                      borderRadius: "10px",
                      marginTop: "15px",
                      bgcolor: "#15131D",
                    }}
                  // onClick={() => handleBoxClick(ticket._id, ticket?.ticketCost)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "13px",
                        height: "80px",
                      }}
                    >
                      <Box>
                        <img src={token.image} width={60} height={60} alt="TokenImages" />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          component="p"
                          variant="body1"
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Inter",
                            fontWeight: 700,
                          }}
                        >
                          {FunctionCommas(token?.TokenName)}
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "2px",
                          }}
                        >
                          {/* <img src={Coin} alt="Coin" width={18} height={18} /> */}
                          <Typography
                            sx={{
                              color: "#FFFFFF",
                              fontWeight: 400,
                              fontSize: {
                                xs: '12px', // Font size 10px for extra small screens (below 400px)
                                sm: '15px', // Font size 12px for small screens and above
                              },
                              fontFamily: "Inter",
                            }}
                          >
                            {token.TokenDescription}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <img src={Arrow} alt="Arrow" />
                  </Box>
                ))}


              </Box>
            </Box>
            {/* <SuccessModal open={modalOpen} handleClose={handleClose} customMessage={popupMessage}/> */}
            {/* {showModal && <MultitapModal onClose={handleCloseModal} />} */}
            {
              notEnoughPoint &&
              <Box sx={{
                position: "absolute",
                height: "100vh",
                width: "100vw",
                backgroundColor: "#1b152993",
                top: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 100000000,
                animation: `${slideUp} 200ms ease-out forwards`,
              }}
              >
                <Box
                  sx={{
                    width: "350px",
                    height: "160px",
                    background: 'linear-gradient(to top right, #7660FF, #C7BDFF)',
                    borderRadius: "16px",
                    display: "flex",

                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      flexDirection: "column",
                      paddingLeft: "15px",
                      paddingTop: "25px",
                      gap: "10px"
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 800,
                        fontSize: "30px",
                        lineHeight: "36px",
                        color: "#fff"

                      }}

                    >Not Enough Points</Typography>
                    <button


                      style={{
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: "14px",
                        background: "#674EFF",
                        borderRadius: "8px",
                        outline: "none",
                        border: "none",
                        color: "#fff",
                        width: "125px",
                        height: "34px",
                      }}

                      onClick={() => navigate("/")}
                    >Keep Tapping</button>
                  </Box>
                  <img src={notEnoughPointCoin} alt="not_enough_point" />
                </Box>

              </Box>
            }
          </Box>
        </>
      )}
    </>
  );
};

export default Boost;
