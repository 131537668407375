import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Backdrop, Fade, Typography, Grid, Paper, Box, Button, useMediaQuery } from '@mui/material';
import { styled, keyframes } from '@mui/system';

import { useDispatch, useSelector } from 'react-redux';
import { claimKlinkRegistrationPointInfo, getKlinkRegistrationInfo, getUserTask, userCompleteTask } from '../Apis';
import bronzeCoin from '../images/BronzeCoin.svg'
// import taskEx from '../images/taskEx.svg'
import CheckIcon from '@mui/icons-material/Check';
import klinkCoin from '../images/KlinkCoin.svg'
import Arrowright from '../images/Arrowright.svg'
import KlinkIcon from '../images/KlinkIcon.svg'
import Coin_ from '../images/Klink-Coin.svg'
import YoutubeKF from '../images/YoutubeKF.svg'
import YoutubeIMG from '../images/YoutubeIMG.svg'
import ArrowRight from "../images/Arrowright.svg"
import { useNavigate } from 'react-router-dom';
import bronze from "../images/Bronze.svg"
import silver from '../images/SilverIcon.svg'; // Replace with the actual path to the silver image
import gold from '../images/Gold.svg'; // Replace with the actual path to the gold image
import Platinum from '../images/Platinum-smallIMG.svg'
import diamond from '../images/Diamand-smallImg.svg'; // Replace with the actual path to the diamond image
import UserRegisterStatus from '../components/UserRegisterStatus';
import TGCommunity from '../images/TG-Community.svg'
import TGChannel from '../images/TG-channel.svg'
import X from '../images/X.svg'
import Discord from '../images/Discord.svg'
import { FunctionCommas } from '../utils/commasFun'
import CircularProgress from '@mui/material/CircularProgress';
import notEnoughPoint from "../images/notEnoughPoint.svg"
import SmallLoader from '../components/smallLodder';
import SuccessFailPopUp from '../components/SuccessFailPopUp';
import CountUp from 'react-countup';










// const FadeContainer = styled(Box)`
//   animation: fadeIn 1s ease-in-out;
//   @keyframes fadeIn {
//     from {
//       opacity: 0;
//     }
//     to {
//       opacity: 1;
//     }
//   }
// `;




const Earn = ({ open, handleClose, task }) => {
  const [tasks, setTasks] = useState([]);
  const [claimButtonVisible, setClaimButtonVisible] = useState(null);
  const [claimedIndex, setClaimedIndex] = useState(null);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [isUrlOpened, setIsUrlOpened] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [id, setId] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.user);
  const userData = useSelector((state) => state.user.userData)
  const userTask = useSelector((state) => state.user.userTask);
  const currentPoint = useSelector((state) => state?.point?.currentPoint);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const [claim, setClaim] = useState(false);
  const [taskKlinkId, setTaskKlinkId] = useState('');
  const [claimLoading, setClaimLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resultPopUp, setResultPopUp] = useState(false);
  const [initialUserPoint, setInitialUserPoint] = useState(currentPoint);

  const isSmallScreen = useMediaQuery('(max-width:395px)');
  const isMaxScreen = useMediaQuery('(max-width:450px)');


  const imageMap = {
    'Join TG Community': TGCommunity,
    'Join Klink TG Chat': TGChannel,
    'Join Klink On X': X,
    'Join Klink On Discord': Discord
  };

  const videoImageMap = {
    'Learn More About Klink Mini App': YoutubeIMG,
    'Learn about prize pools': YoutubeIMG

  };
  // }

  // const customImage = {
  //   "Join Klink TG Community": TGCommunity,
  //    " ": silver,
  //   Gold: gold,
  //   Diamond: diamond,
  // };






  const leagueImages = {
    Bronze: bronze,
    Silver: silver,
    Gold: gold,
    Platinum: Platinum,
    Diamond: diamond,
  };

  // console.log("task userData =====> ", userData);

  // console.log("userTask", userTask);


  useEffect(() => {
    dispatch(getKlinkRegistrationInfo(userId, setClaim, setTaskKlinkId, accessToken, setClaimLoading))
  }, [userId])



  const completeTask = async (id, taskPoints) => {
    setSelectedTaskId(id);
    dispatch(userCompleteTask(id, setCompletedTasks, userId, setShowLoader, accessToken, taskPoints, setResultPopUp, setSuccess ))

  };

  const [isUrlOpenedSocials, setIsUrlOpenedSocials] = useState([]);
  const [isUrlOpenedVideos, setIsUrlOpenedVideos] = useState([]);

  // const handleClaimButtonClick = (index, task) => {
  //   if (!isUrlOpened[index]) {
  //     if (task.socialUrl) {
  //       window.open(task.socialUrl, '_blank');
  //     }
  //     const updatedIsUrlOpened = [...isUrlOpened];
  //     updatedIsUrlOpened[index] = true;
  //     setIsUrlOpened(updatedIsUrlOpened);
  //   } else {
  //     setShowLoader((prevState) => ({ ...prevState, [task._id]: true }));
  //     completeTask(task._id);
  //     const updatedIsUrlOpened = [...isUrlOpened];
  //     updatedIsUrlOpened[index] = false;
  //     setIsUrlOpened(updatedIsUrlOpened);
  //   }
  // };
  const handleClaimButtonClick = (index, task, category) => {
    const isUrlOpened = category === 'socials' ? isUrlOpenedSocials : isUrlOpenedVideos;
    const setIsUrlOpened = category === 'socials' ? setIsUrlOpenedSocials : setIsUrlOpenedVideos;

    if (!isUrlOpened[index]) {
      if (task.socialUrl) {
        window.open(task.socialUrl, '_blank');
      }
      const updatedIsUrlOpened = [...isUrlOpened];
      updatedIsUrlOpened[index] = true;
      setIsUrlOpened(updatedIsUrlOpened);
    } else {
      setShowLoader((prevState) => ({ ...prevState, [task._id]: true }));
      completeTask(task._id, task.taskPoints);
      const updatedIsUrlOpened = [...isUrlOpened];
      updatedIsUrlOpened[index] = false;
      setIsUrlOpened(updatedIsUrlOpened);
    }
  };

  // useEffect(() => {
  //   dispatch(getUserTask(userId, accessToken));
  // }, [userId]);

  const handelRedirectToRegister = () => {
    try {
      navigate("/register");

    } catch (error) {


    };
  }

  const handelRedirect = () => {
    navigate("/user-level-details")
  }
  const fromLeft = keyframes`
  0% {
      transform: translateX(100%);
      opacity: 0;
    }
   
    50%{
     transform: translateX(2%);
      opacity: 0.5;
     
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

  const fromTop = keyframes`
  0% {
        transform: translateY(-15%);
        opacity: 0;
    }
   
    50%{
 opacity: 0.5;


    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;
  const fromRight = keyframes`
   0% {
        transform: translateX(-10%);
     
    }
    100% {
        transform: translateX(0);
      
    }
`;
  const boxFromLeft = keyframes`
  0% {
        transform: translateX(10%);
     
    }
    100% {
        transform: translateX(0);
      
    }
`;

  const boxFromRight = keyframes`
  0% {
        transform: translateX(-10%);
     
    }
    100% {
        transform: translateX(0);
      
    }
`;

  return (
    <>
      <UserRegisterStatus />
      <Box style={{ height: '85vh', marginLeft: "21px", marginRight: "22px" }}>
        <Box
          sx={{
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // padding: "5px",
            cursor: "pointer"
            // textAlign: 'left',
            // width: '95%',
            // height: "60px",
            // margin: '8px',
            // padding: '10px',
            // borderRadius: '10px',
            // border: '1px solid #313130',
            // display: 'flex',
            // justifyContent: 'space-between',
            // alignItems: 'center',
            // ":hover": {
            //   cursor: "pointer"
            // }
          }}

        >
          <Box textAlign="center" display="flex" justifyContent="center" alignItems="center" width={'100%'} flexDirection={'column'} marginTop={'29px'}  >
            <Box sx={{ textAlign: 'center', width: '100%', marginBottom: "39px" }}>
              <Box sx={{
                animation: `${fromTop} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
              }} fontFamily="Inter" fontWeight={700} color="white" fontSize="1rem" display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'9.82px'} >
                <img src={Coin_} width={32.31} height={38.2} />
                <Typography sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: "42px", lineHeight: "50.4px" }}>
                  {/* {FunctionCommas(currentPoint)} */}
                  <CountUp duration={5} preserveValue={true} start={initialUserPoint} end={currentPoint} />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "13.5px",
                  zIndex: 10000000,
                  marginTop: "16px"
                }}
                onClick={handelRedirect}

              >

                <img src={leagueImages[userData.league] || bronze} width={40} height={40} alt="league_image" style={{ objectFit: "cover" }} />



                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: 700,
                    fontSize: "18px",
                    display: "flex",
                    justifyContent: "center",
                    lineHeight: "23.4px",
                    color: "#FFFFFF"
                  }}
                >
                  {userData?.league}
                </Typography>

                <img src={ArrowRight} alt="arrow_image" />
              </Box>
            </Box>

            <Box sx={{height: '60vh', position: "relative", overflowY: "auto",    width: "100%" }}>

         

            <Box sx={{ width: '100%', marginBottom: "15px" }}>
              <Typography sx={{ textAlign: 'left', color: '#FFFFFF', fontFamily: "Inter", fontWeight: 700, fontSize: "18px", lineHeight: "23.4px",  paddingLeft: "10px"  }}>
                Register Account
              </Typography>
            </Box>

            <Box
              bgcolor="#16131D"
              color="white"
              sx={{
                textAlign: 'left',
                width: isSmallScreen ? "90%" : "92%",
                height: "60px",
                margin: '8px',
                padding: '10px',
                borderRadius: '12px',
                border: '1px solid #313130',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                pointerEvents: userData?.isUserVerified && claim ? 'none' : 'auto', // Disable pointer events if verified and claimed
                opacity: userData?.isUserVerified && claim ? 0.7 : 1,
                // Optionally reduce opacity for disabled state
              }}
              onClick={!userData?.isUserVerified || !claim ? handelRedirectToRegister : undefined}
            >
              <img
                src={KlinkIcon}
                width={40}
                height={40}
                style={{
                  marginRight: '10px',
                  padding: '5px',
                  borderRadius: '10px',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  flexGrow: 1,
                  cursor: userData?.isUserVerified && claim ? 'not-allowed' : 'pointer', // Change cursor based on disabled state
                }}
              // Only handle click if not disabled
              >
                <Typography
                  style={{
                    color: '#FFFFFF',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '17.5px',
                    fontFamily: 'Inter',
                   
                  }}
                >
                  Register Klink Account
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: "5px" }}>
                  <img src={Coin_} width={16} height={16} />
                  <Typography
                    style={{
                      color: '#FFFFFF',
                      lineHeight: '17.5px',
                      fontWeight: 700,
                      fontFamily: 'Inter',
                      fontSize: "12px"
                    }}
                  >
                    50,000
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginRight: "auto" }}>
                {userData?.isRegRewClaimed ? <>
                  <CheckIcon
                    sx={{
                      alignItems: 'center',
                      background: '#674EFF',
                      borderRadius: '50%',
                      display: 'flex',
                      height: '22px',
                      justifyContent: 'center',
                      width: '22px',
                      color: "white"
                    }}
                  />

                </> : <>
                  {
                    userData?.isUserVerified ? (
                      <>
                        {
                          !claimLoading ?
                            <>

                              {claim ? (
                                <CheckIcon
                                  sx={{
                                    alignItems: 'center',
                                    background: '#674EFF',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    height: '22px',
                                    justifyContent: 'center',
                                    width: '22px',
                                    color: "white"
                                  }}
                                />
                              ) : (
                                <button
                                  style={{
                                    color: "#FFFFFF",
                                    backgroundColor: "#674EFF",
                                    borderRadius: "10%",
                                    marginLeft: '10px',
                                    '&:hover': {
                                      backgroundColor: "#674EFF",
                                    },
                                    outline: "none",
                                    border: "None",
                                    padding: "8px",
                                    cursor: "pointer",
                                  }}
                                  disabled={claim} // Disable the button if claim is true
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(claimKlinkRegistrationPointInfo(userId, taskKlinkId, accessToken, setClaimLoading))
                                  }}
                                >
                                  claim
                                </button>
                              )}
                            </> : <>
                              <CircularProgress
                                size={28} // Adjust the size if needed
                                value={20}
                                sx={{
                                  color: '#674EFF',
                                }}
                              />

                              {/* <SmallLoader /> */}

                            </>
                        }
                      </>
                    ) : (
                      <img src={Arrowright} width={32} height={32} />
                    )
                  }
                </>}
              </Box>
            </Box>


            <Box sx={{ width: '100%', marginTop: "25px", marginBottom: "15px" }}>
              <Typography sx={{ textAlign: 'left', color: '#FFFFFF', fontFamily: "Inter", fontWeight: 700, fontSize: "18px", lineHeight: "23.4px", paddingLeft: "10px"  }}>
                Join Our Community
              </Typography>
            </Box>

            {userTask?.filter(task => task.category === 'socials').map((task, index) => (
              <Box
                key={task._id}
                bgcolor="#15131D"
                color="white"
                sx={{
                  textAlign: 'left',
                  width: isSmallScreen ? "90%" : "92%",
                  height: "60px",
                  margin: '8px',
                  padding: '10px',
                  borderRadius: '12px',
                  border: '1px solid #313130',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,


                }}
              >
                <img
                  //  src={task.taskLogoUrl}
                  src={imageMap[task.taskName] || task.taskLogoUrl}
                  width={40}
                  height={40}
                  style={{
                    marginRight: '10px',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    style={{
                      color: '#FFFFFF',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '17.5px',
                      fontFamily: 'Inter',
                    }}
                  >
                    {task.taskName}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: "5px" }}>
                    <img src={Coin_} width={16} height={16} />
                    <Typography
                      style={{
                        color: '#FFFFFF',
                        lineHeight: '17.5px',
                        fontWeight: 700,
                        fontFamily: 'Inter',
                        fontSize: "12px"
                      }}
                    >
                      {task.taskPoints}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* <Button
                    onClick={() => handleClaimButtonClick(index, task)}
                    disabled={task.completed}
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: isUrlOpened[index] ? "#674EFF" : "transparent",
                      borderRadius: "10%",
                      marginLeft: '10px',
                      '&:hover': {
                        backgroundColor: isUrlOpened[index] ? "#674EFF" : "transparent",
                      },
                    }}
                  >
                    {task.completed ? (
                      <CheckIcon sx={{
                        alignItems: 'center',
                        background: '#674EFF',
                        borderRadius: '50%',
                        display: 'flex',
                        height: '22px',
                        justifyContent: 'center',
                        width: '22px',
                        color: "white"
                      }} />
                    ) : isUrlOpened[index] ? (
                      "Claim"
                    ) : (
                      <img src={Arrowright} width={32} height={32} sx={{ backgroundColor: "transparent" }} alt="Task Example" />
                    )}
                  </Button> */}
                  <Button
                    onClick={() => handleClaimButtonClick(index, task, 'socials')}
                    disabled={task.completed}
                    sx={{
                      color: '#FFFFFF',
                      backgroundColor: isUrlOpenedSocials[index] ? '#674EFF' : 'transparent',
                      borderRadius: '10%',
                      marginLeft: '10px',
                      minWidth: "0px !important",
                      '&:hover': {
                        backgroundColor: isUrlOpenedSocials[index] ? '#674EFF' : 'transparent',
                      },
                    }}
                  >
                    {
                      selectedTaskId == task?._id && showLoader ? <>
                        <CircularProgress
                          size={28} // Adjust the size if needed
                          value={20}
                          sx={{
                            color: '#674EFF',
                          }}
                        />

                      </> :
                        <>
                          {
                            task.completed ? (
                              <CheckIcon
                                sx={{
                                  alignItems: 'center',
                                  background: '#674EFF',
                                  borderRadius: '50%',
                                  display: 'flex',
                                  height: '22px',
                                  justifyContent: 'center',
                                  width: '22px',
                                  color: 'white',
                                }}
                              />
                            ) : isUrlOpenedSocials[index] ? (
                              'Claim'
                            ) : (
                              <img src={Arrowright} width={32} height={32} sx={{ backgroundColor: 'transparent' }} alt="Task Example" />
                            )
                          }
                        </>

                    }
                  </Button>
                </Box>
              </Box>
            ))}

            <Box sx={{ width: '100%', marginTop: "25px", marginBottom: "15px" }}>
              <Typography sx={{ textAlign: 'left', color: '#FFFFFF', fontFamily: "Inter", fontWeight: 700, fontSize: "18px", lineHeight: "23.4px" , paddingLeft: "10px" }}>
                Watch Explainer Video
              </Typography>
            </Box>
            {userTask?.filter(task => task.category === 'videos').map((task, index) => (
              <Box
                key={task._id}
                bgcolor="#16131D"
                color="white"
                sx={{
                  textAlign: 'left',
                  width: isSmallScreen ? " 90%" : "92%",
                  height: "60px",
                  margin: '8px',
                  padding: '10px',
                  borderRadius: '12px',
                  border: '1px solid #313130',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  animation: `${fromLeft} 0.4s   ease-in-out`,
                }}
              >
                <img
                  src={videoImageMap[task.taskName] || YoutubeKF}
                  width={40}
                  height={40}
                  style={{
                    marginRight: '10px',
                    padding: '5px',
                    borderRadius: '10px',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    style={{
                      color: '#FFFFFF',
                      fontSize: isSmallScreen ? '12px' : '14px',
                      fontWeight: 700,
                      lineHeight: '17.5px',
                      fontFamily: 'Inter',
                      letterSpacing: "0px",
                    }}
                  >
                    {task.taskName}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5.37px', marginTop: "5px" }}>
                    <img src={Coin_} width={16} height={16} />
                    <Typography
                      style={{
                        color: '#FFFFFF',
                        lineHeight: '17.5px',
                        fontWeight: 700,
                        fontFamily: 'Inter',
                        fontSize: "12px"
                      }}
                    >
                      {task.taskPoints}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* <Button
                    onClick={() => handleClaimButtonClick(index, task)}
                    disabled={task.completed}
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: isUrlOpened[index] ? "#674EFF" : "transparent",
                      borderRadius: "10%",
                      marginLeft: '10px',
                      '&:hover': {
                        backgroundColor: isUrlOpened[index] ? "#674EFF" : "transparent",
                      },
                    }}
                  >
                    {task.completed ? (
                      <CheckIcon sx={{
                        alignItems: 'center',
                        background: '#674EFF',
                        borderRadius: '50%',
                        display: 'flex',
                        height: '22px',
                        justifyContent: 'center',
                        width: '22px',
                        color: "white"
                      }} />
                    ) : isUrlOpened[index] ? (
                      "Claim"
                    ) : (
                      <img src={Arrowright} width={32} height={32} sx={{ backgroundColor: "transparent" }} alt="Task Example" />
                    )}
                  </Button> */}
                  <Button
                    onClick={() => handleClaimButtonClick(index, task, 'videos')}
                    disabled={task.completed}
                    sx={{
                      color: '#FFFFFF',
                      backgroundColor: isUrlOpenedVideos[index] ? '#674EFF' : 'transparent',
                      borderRadius: '10%',
                      marginLeft: '10px',
                      minWidth: "0px !important",
                      padding: task.completed ? "0px !important" : (isUrlOpenedVideos[index] ? "6px 8px !important" : "0px !important"),
                      '&:hover': {
                        backgroundColor: isUrlOpenedVideos[index] ? '#674EFF' : 'transparent',
                      },
                    }}
                  >
                    {
                      selectedTaskId == task?._id && showLoader ? <>
                        <CircularProgress
                          size={28} // Adjust the size if needed
                          value={20}
                          sx={{
                            color: '#674EFF',
                          }}
                        />

                      </> :
                        <>
                    {task.completed ? (
                      <CheckIcon
                        sx={{
                          alignItems: 'center',
                          background: '#674EFF',
                          borderRadius: '50%',
                          display: 'flex',
                          height: '22px',
                          justifyContent: 'center',
                          width: '22px',
                          color: 'white',
                        }}
                      />
                    ) : isUrlOpenedVideos[index] ? (
                      'Claim'
                    ) : (
                      <img src={Arrowright} width={32} height={32} sx={{ backgroundColor: 'transparent' }} alt="Task Example" />
                    )}
                    
                        </>

                    }
                  </Button>
                </Box>
              </Box>
            ))}
   </Box>

            {resultPopUp && <SuccessFailPopUp navigate={navigate} setResultPopUp={setResultPopUp} success={success} />}
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default Earn
