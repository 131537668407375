import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  keyframes
} from "@mui/material";
import star_background from "../images/Star_background.svg"
import { styled } from "@mui/system";
import MyProgress from "../components/Progress";
import coin from "../images/Coin.svg";
import flash from "../images/flash.svg";
import { useDispatch } from "react-redux";
import { setCurrentTokenCount } from '../store/slices/pointSlice'
import pigLevel from '../images/Pig.svg'
import { SetBoostData, callTapApi } from "../Apis";
import KlinkCoin from '../images/KlinkCoin.svg'
import { increaseUserTapCount, reduceCurrentEnergy, restoreEnergy, setCurrentEnergy, setUserTotalTaps } from "../store/slices/miningInfoSlice";
import ArrowRight from "../images/Arrowright.svg"
import { useNavigate } from "react-router-dom";
import IconCoin2 from "../images/Icon-Coin2.svg";
import userIcon from '../images/userIcon.svg';
import bronze from "../images/Bronze.svg"
import silver from '../images/SilverIcon.svg'; // Replace with the actual path to the silver image
import gold from '../images/Gold.svg'; // Replace with the actual path to the gold image
import Platinum from '../images/Platinum-smallIMG.svg'
import diamond from '../images/Diamand-smallImg.svg'; // Replace with the actual path to the diamond image
import UserRegisterStatus from "../components/UserRegisterStatus";
import KlinkToken from '../images/Klink-Coin.svg'
import KlinkBubbel from "../images/KlinkBubbel.svg"
import CountUp from 'react-countup';
import pointsSvg1 from "../images/Points_1.svg"
import pointsSvg2 from "../images/Points_2.svg"
import pointsSvg3 from "../images/Points_3.svg"
import pointsSvg4 from "../images/Points_4.svg"
import pointsSvg5 from "../images/Points_5.svg"
const isDesktop = window.innerWidth > 1000;



const floatUpAndFadeOut = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
  }
`;



export default function CoinApp(props) {
  const [fontSizeAnimation, setFontSizeAnimation] = useState("");
  const [userCurrentSkinID, setUserCurrentSkinID] = useState();
  const [isShaking, setIsShaking] = useState(false);
  const userMiningInfo = useSelector((state) => state?.miningInfo?.miningInfo)
  const userCurrentEnergy = useSelector((state) => state?.miningInfo?.miningInfo?.currentEnergy)
  // console.log("userCurrentEnergy", userCurrentEnergy);
  const [textPoints, setTextPoints] = useState([]);
  const timeoutRef = useRef(null);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const currentPoints = useSelector((state) => state.point.currentPoint);
  const userPoint = useSelector((state) => state?.point?.userPoint);
  const totalTaps = useSelector((state) => state?.miningInfo?.totalTaps)
  const userData = useSelector((state) => state?.user?.userData);
  // const UserMaxEngergy = useSelector((state) => state?.user?.userData?.boosts?.energyLimit?.currentValue);

  const accessToken = useSelector((state)=>state?.user?.accessToken);
  const [coords, setCoords] = useState({ x: 4, y: 4 });
  const [initialUserPoint,setInitialUserPoint] = useState(0);
  const [accountConnected,setAccountConnected] = useState(false);
  const navigate = useNavigate();




  const leagueImages = {
    Bronze: bronze,
    Silver: silver,
    Gold: gold,
    Platinum: Platinum,
    Diamond: diamond,
  };

  useEffect(() => {
    setInitialUserPoint(currentPoints || 0);
  }, [userData]);

  const handleTouchStart = (e) => {
    Array.from(e.touches).forEach(touch => {
      handleTap(touch.clientX, touch.clientY);
    });
  };


  const handleTap = (clientX, clientY) => {
    if (userCurrentEnergy <= 0) return;

    dispatch(increaseUserTapCount());
    dispatch(reduceCurrentEnergy(userData?.boosts?.rechargingSpeed?.currentValue));
    dispatch(setCurrentTokenCount(userMiningInfo?.tapPoints ? userMiningInfo?.tapPoints : 1));

    const rect = document.querySelector('.tap-area').getBoundingClientRect();
    const x = clientX - rect.left;
    const y = clientY - rect.top;

    setTextPoints(prevPoints => [...prevPoints, { x, y, id: Date.now() }]);

    if (window.navigator && window.navigator.vibrate) {
      navigator.vibrate(50);
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      dispatch(callTapApi(currentPoints, userPoint, userId, userMiningInfo?.tapPoints, totalTaps, userMiningInfo?.currentEnergy, accessToken));
    }, 500);
  };



  // const handleClick = (e) => {
  //   if (userCurrentEnergy <= 0) return;
  //   dispatch(increaseUserTapCount());
  //   dispatch(reduceCurrentEnergy(userData?.boosts?.rechargingSpeed?.currentValue));
  //   dispatch(setCurrentTokenCount(userMiningInfo?.tapPoints ? userMiningInfo?.tapPoints : 1))
  //   const rect = e.target.getBoundingClientRect();
  //   const clickX = e.clientX - rect.left;  // X position of click relative to the left edge of the coin
  //   const centerX = rect.width / 2;

  //   // Determine if click is on left or right side
  //   const isLeftSide = clickX <= centerX;
  //   const x = isLeftSide ? 10 : 0;  // Adjust the angle based on left or right side
  //   const y = !isLeftSide ? 10 :0;  // No vertical rotation

  //   setCoords({ x, y });
   
  //   if (window.navigator && window.navigator.vibrate) {
  //     // Vibrate for 200ms
  //     navigator.vibrate(50);
  //   } else {
  //     console.log('Vibration API not supported');
  //   }
  //   if (e.touches && e.touches.length > 0) {
  //     // Touch event
  //     const rect = e.currentTarget.getBoundingClientRect();
  //     const x = e.touches[0].clientX - rect.left;
  //     const y = e.touches[0].clientY - rect.top;  
  //     setTextPoints([...textPoints, { x, y, id: Date.now() }]);
  //   } else {
  //     // Mouse click event
  //     const rect = e.currentTarget.getBoundingClientRect();
  //     const x = e.clientX - rect.left;
  //     const y = e.clientY - rect.top;
  //     setTextPoints([...textPoints, { x, y, id: Date.now() }]);
  //   }
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }

  //   timeoutRef.current = setTimeout(() => {
  //     dispatch(callTapApi(currentPoints, userPoint, userId, userMiningInfo?.tapPoints, totalTaps, userMiningInfo?.currentEnergy, accessToken))
  //   }, 495);
  // };

  console.log("userMiningInfo ===========>", userCurrentEnergy);
  const handelRedirect = () => {
    return navigate("/user-level-details")
  }

  const getStyle = () => {
    const baseStyle = {
      position: "absolute",
      top: "73vh",
      color: "aliceblue",
      animation: fontSizeAnimation,
      fontFamily: "avenir",
      fontSize: isDesktop ? "18px" : "13px",
      // width: "92%", // Default width value
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "5px",

      "@media (minWidth: 769px)": {
        left: "25vw", // Default left value for larger screens
      },

      // Media query for screens 768px or less
      "@media (maxWidth: 768px)": {
        width: "92vw", // Adjust width for smaller screens
        // Additional styles specific to smaller screens
      },
    };

    return baseStyle;
  };


  const removePoint = (id) => {
    const result = textPoints.filter((point) => point?.id != point?.id);
    setTextPoints(result);
  };
  const scaleUp = keyframes`
      0% {
        transform: scale(0);
    }
   
    50% {
        transform: scale(0.8);
    }

    100%{
     transform: scale(1);
    }
`;

const fromRight= keyframes`
 0% {
        transform: translateX(-70%);
      opacity: 0;
    }
   
   70%{
    transform: translateX(5%);
    opacity: 0.8;
   }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;
const fromLeft = keyframes`
  0% {
        transform: translateX(70%);
        opacity: 0;
    }
   

    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

  const FadeContainer = styled(Box)`
 animation: fadeIn 1s ease-in-out;
 @keyframes fadeIn {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
`;

  const animation = `

  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
  const animation2 = `
   @keyframes rotateAnti {
  0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
  }
`;

  const StyledImage = styled(Box)`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius:100%;
  overflow:hidden;
 animation: rotate 25s linear infinite;
  ${animation}
`;
  const StyledImage2 = styled(Box)`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
 animation: rotateAnti 20s linear infinite;
  ${animation2}
`;
const skewAnimation = keyframes`
 0% {
    transform: perspective(600px) rotateX(0) rotateY(0);
  }
  100% {
    transform: perspective(600px) rotateY(${coords?.x}deg) rotateY(${coords?.y}deg);
  }
`;
  return (
    <>
     <UserRegisterStatus/>
   
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          overflow: 'hidden' 

        }}
      >

        <img src={star_background} style={{ position: "absolute", zIndex: -1, top: 0, bottom: 0, right: 0, left: 0, width: "100%", height: "100%", }} />
        {/* <StyledImage
      component="img"
      src={star_background}
      alt="Styled Image"
    />
        <StyledImage2
      component="img"
      src={star_background}
      alt="Styled Image"
    /> */}
        <Box
          sx={{
            position: "absolute",
            top: "125px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "326px",
            height: "114px",
            display: "flex",
            // alignItems: 'center',
            // justifyContent: 'center',
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
   
              <img src={KlinkToken} width={36} height={40}  style={{
                 animation: `${scaleUp} 0.2s cubic-bezier(0.25, 0.8, 0.25, 1)`
              }}/>
      
            <Typography
        sx={{
          color: "#FFFFFF",
          fontSize: "42px",
          fontWeight: 700,
          fontFamily: "Inter",
          lineHeight: "50.4px",
          animation: `${fromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
        }}
      >
        <CountUp separator="," duration={5} preserveValue={true} start={initialUserPoint} end={currentPoints} />
      </Typography>


          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "85px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "135px",
              height: "42px",
              display: "flex",
              // alignItems: 'center',
              // justifyContent: 'center',
              borderRadius: "12px",
              flexDirection: "column",
              color: "#F4FDFFDF",

            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0px",
                zIndex: 10000000
              }}
              onClick={handelRedirect}

            >

              <img src={leagueImages[userData.league] || bronze} width={40} height={40} style={{ objectFit: "cover", marginRight: "13.04px"}} alt="league_image" />



              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "20px",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                { }
                {userData?.league}
              </Typography>

              <img src={ArrowRight} alt="arrow_image"  width={32} height={32} style={{marginLeft: "5px"}}/>
            </Box>
          </Box>

        </Box>

        <div style={{ position: "relative", top: "28vh" }}>
          {/* <Typography sx={{ color: "white", textAlign: "center", fontFamily: "Lexend", fontWeight: 700 }}>

            Rank:   {userRank === null
              ? "Loading..."
              : userRank === 1
                ? "1st"
                : userRank === 2
                  ? "2nd"
                  : userRank === 3
                    ? "3rd"
                    : `${userRank}th`}
          </Typography> */}
         <Box
        className="tap-area"
        sx={{
          position: "relative",
          width: 243,
          height: 243,
        }}
        onTouchStart={handleTouchStart}
        // onClick={handleClick}
      >
        <Box
          component="img"
          src={KlinkBubbel}
          alt="Coin Logo"
          sx={{
            width: "100%",
            height: "100%",
            transition: 'transform 0.5s ease',
            // filter: `drop-shadow(${getCoinSkinShadow(userCurrentSkinID)})`,
            "&:hover": { cursor: "pointer" },
            animation: `${scaleUp} 0.2s cubic-bezier(0.25, 0.8, 0.25, 1)`,
            animation: `${skewAnimation} 50ms`,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            background: "#000",
            width: "250px",
            height: "250px",
            bottom: 0,
            top: 0,
            right: 0,
            left: 0,
            background: "radial-gradient(circle, #E8B5FF, #FFD5C9);",
            borderRadius: "100%",
            zIndex: -1,
            filter: "blur(80.6px)"
          }}
        ></Box>
        {textPoints?.map((point) => (
          <Box
            key={point.id}
            sx={{
              position: "absolute",
              left: point.x - 10,
              top: point.y - 20,
              animation: `${floatUpAndFadeOut} 2s ease forwards`,
              fontSize: "35px",
              fontFamily: "Inter",
              color: "#FBC44D",
            }}
            onAnimationEnd={() => setTimeout(() => removePoint(point.id), 1000)}
          >
            {userMiningInfo?.tapPoints == 1 && <img src={pointsSvg1} alt="svg_1" />}
            {userMiningInfo?.tapPoints == 2 && <img src={pointsSvg2} alt="svg_2" />}
            {userMiningInfo?.tapPoints == 3 && <img src={pointsSvg3} alt="svg_3" />}
            {userMiningInfo?.tapPoints == 4 && <img src={pointsSvg4} alt="svg_4" />}
            {userMiningInfo?.tapPoints == 5 && <img src={pointsSvg5} alt="svg_5" />}
          </Box>
        ))}
      </Box>
        </div>
        <div style={getStyle()} className="mining-info-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

            }}
          >
            <img
              style={{ verticalAlign: "bottom", marginRight: "5px" }}
              width={"20px"}
              height={"20px"}
              src={KlinkToken}
              alt="flash-on"
            />
            <div style={{ marginRight: "auto" }}>
             <span 
             style={{
              fontFamily: "Inter",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21.78px",
             }}
             > {userCurrentEnergy}</span> <span
             
             style={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "14.52px",
             }}
             >/ {userData?.boosts?.energyLimit?.currentValue}</span>
            </div>
          </Box>
          <MyProgress value={userCurrentEnergy} max={userData?.boosts?.energyLimit?.currentValue} />
        </div>
      </Box>

    </>
  );
}
